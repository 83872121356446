var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[(!_vm.isDefalutPos)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label)+" "),(_vm.checkIsRequired)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()]),_c('span',{staticStyle:{"margin-right":"5px"}},[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})])])]),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon",staticStyle:{"padding-left":"10px"}},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{staticStyle:{"display":"contents"},attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(!_vm.isList)?_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon,"clearable":""},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e(),(_vm.isList)?_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon,"clearable":""},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e()],1)],1):_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{staticClass:"label-size",attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label)+" "),(_vm.checkIsRequired)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)])]),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon",staticStyle:{"padding-left":"10px"}},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(!_vm.isList)?_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon,"clearable":""},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e(),(_vm.isList)?_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon,"clearable":""},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }